.title {
  margin-bottom: 5%;
  letter-spacing: .6em;
  padding-left: 1%;
}

.projects-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-container {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3%;
  justify-items: center;
  padding-left: 3%;
  padding-right: 3%;
}

.project {
  perspective: 1000px;
  height: 250px;
  width: 500px;
  padding: 4%;
}

.project-inner {
  transform-style: preserve-3d; 
  transition: transform 1s;
  width: 100%; 
  height: 100%; 
}

.project:hover .project-inner {
  transform: rotateY(180deg);
}

.project:hover .project-back {
  z-index: 2;
}

.project-front,
.project-back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.189);
}

.project-front {
  box-sizing: border-box;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size:auto;
  background-position: center;
}

.project-front > img {
  height: 100%;
  border: 1.5px solid rgba(255, 255, 255, 0.152);
  border-radius: 5px;
}

.project-back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(38, 70, 83);
  border: 1px solid rgb(255, 255, 255, 0.355);
  border-radius: 5px;
  color: white;
  box-sizing: border-box;
  padding: 2% 4% 2% 4%;
}

.links {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 4%;
}

.links > a {
  color:rgb(233, 196, 106);
  letter-spacing: .4em;
  transition: all .2s ease-in;
}

.links > a:hover {
  letter-spacing: .6em;
}

.project-back > h2 {
  color: rgb(233, 196, 106);
  letter-spacing: .1em;
  margin: .3em;
  font-size: 1.8em;
}

.project-back > p {
  font-size: .9em;
  margin: .3em;
  letter-spacing: .1em;
  text-align: center;
}

@media screen and (max-width: 1800px) {
  .projects-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1200px) {
  .projects-container {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    row-gap: 3%;
  }
  .project {
    max-height: 19rem;
  }
  .project-back {
    max-height: 19rem;
  }
}

@media screen and (max-width: 900px) {
  .links > a {
    font-size: 1em;
  }
  .links > a:hover {
    letter-spacing: .4em;
  }
}

@media screen and (max-width: 621px) {
  .project {
    height: 145px;
    width: 290px;
  }
  .project-back {
    max-height: 10rem;
    font-size: .7em;
  }
  .title {
    padding-left: 4%;
  }
}