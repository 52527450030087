.header {
  padding: .5% 0% .5% 0%;
  margin-bottom: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  background-color: rgb(42, 157, 143);
}

.logo {
  font-family: 'PT Mono', monospace;
  margin: 0;
  text-align: center;
  height: 15%;
}