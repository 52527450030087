html, body, .App, #root {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  text-align: center;
  margin: 0;
  background-color: rgba(38, 70, 83);
  color: white;
  height: 100vh;
}

a {
  color: white;
}

a:hover {
  color:rgb(255, 195, 0)
}