.intro {
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro > h1 {
  margin-top: 7%;
  letter-spacing: .2em;
  text-align: justify;
  text-align-last: center;
  width: 75%;
}

.my-name {
  color: rgb(233, 196, 106);
}

@media screen and (max-width: 1200px) {
  .intro > h1 {
    margin-top: 25%;
  }
}

@media screen and (max-width: 850px) {
  .intro > h1 {
    width: 60%;
  }
}

@media screen and (max-width: 815px) {
  .intro > h1 {
    font-size: 2em;
    letter-spacing: .1em;
  }
}

@media screen and (max-width: 550px) {
  .intro > h1 {
    /* text-align: center; */
    font-size: 1.3em;
    letter-spacing: normal;
    width: 85%;
    margin-top: 50%;
  }
}