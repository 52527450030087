.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about > img {
  height: 30rem;
  border-radius: 4px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.102);
}

.about {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.about-me {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-me > p {
  width: 100%;
  font-size: 1.3em;
  letter-spacing: .2em;
  display: flex;
  align-items: center;
  text-align: justify;
  text-align-last: center;
  line-height: 1.4em;
}

.bar-chart {
  width: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart {
  width: 70%;
}

.bar-chart > h2 {
  letter-spacing: .5em;
  font-size: 2em;
}

@media screen and (max-width: 1200px) {
  .about {
    width: 100%;
  }
  .chart {
    width: 80%;
  }
  .about-me {
    width: 50%;
  }
  .about-me > p {
    letter-spacing: normal;
    line-height: 1.4em;
  }
}

@media screen and (max-width: 1000px) {
  .chart {
    width: 85%;
  }
  .about {
    width: 90%;
  }
  .about > img {
    height: 25em;
  }
  .about-me {
    width: 50%;
  }
  .about-me > p {
    font-size: 1.1em;
    letter-spacing: normal;
  }
  /* .chart canvas {
    height: 500px !important;
  } */
}

@media screen and (max-width: 750px) {
  .chart {
    width: 96%;
  }
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about > img {
    width: 15em;
    height: 20em;
  }
  .about-me {
    width: 95%;
  }
}