.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-page > p {
  font-size: 1.2em;
  width: 40%;
  letter-spacing: 0.5em;
  line-height: 1.4em;
  text-align: justify;
  text-align-last: center;
  justify-content: center;
}

.form-inputs {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.name, .email, .message {
  font-family: 'Raleway', sans-serif;
  margin: 1%;
  width: 50%;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.189);
  border: 0;
  border-radius: 4px;
}

.message {
  height: 23rem;
  width: 50%;
}

.submit {
  width: 20%;
  align-self: center;
  background-color: rgba(38, 70, 83);
  border: rgb(42, 157, 143) 1px solid;
  border-radius: 3px;
  padding: .5em;
  color:white;
  font-family: 'Raleway', sans-serif;
  letter-spacing: .4em;
  transition: all .2s ease-in;
}

.submit:hover {
  background-color: rgb(233, 196, 106);
  color: rgb(38, 70, 83);
  border: rgb(38, 70, 83) 1px solid;
  letter-spacing: .6em;
}


@media screen and (max-width: 950px) {
  .contact-form {
    width: 90%;
    margin: 0;
  }
  .name, .email, .message, .submit {
    width: 75%;
  } 
  .contact-page > p {
    width: 75%;
    letter-spacing: 0.06em;
    margin-top: 5%;
  }
}

@media screen and (max-width: 400px) {
  .name, .email, .message, .submit {
    width: 100%;
  } 
  .contact-page > p {
    width: 90%;
    letter-spacing: 0.06em;
  }
}